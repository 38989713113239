import { Constants } from '../AppConstants'
import { TimePeriod } from '../library/TimePeriod';

export const FormUtil = function () {
    function traverseEntity(entity, attribute) {
        if (attribute.indexOf(".") === -1) {
            // Then we can just return the entity as is
            return entity;
        }
        else {
            // Otherwise we have to traverse the entity
            const attributeParts = attribute.split('.');
            let traversed = entity;
            for (let i = 0; i < attributeParts.length - 1; i++) {
                let subattribute = attributeParts[i];
                traversed = traversed[subattribute];
            }
            return traversed;
        }
    }
    function getLeafAttribute(attribute) {
        if (attribute.indexOf(".") === -1) {
            // Then we can just return the attribute as is
            return attribute;
        }
        else {
            // Otherwise return the outmost part of the attribute
            const attributeParts = attribute.split('.');
            return attributeParts[attributeParts.length - 1];
        }
    }
    return {
        hasErrors: function (errors) {
            for (let prop in errors) {
                if (errors[prop]) return true;
            }
            return false;
        },
        isArrayEqual: function (arr1, arr2) {
            if (arr1.length === arr2.length) {
                for (let i = 0; i < arr1.length; i++) {
                    const val1 = arr1[i];
                    for (let j = 0; j < arr2.length; j++) {
                        if (arr2[j] !== val1) {
                            return false;
                        }
                    }
                }
                return true;
            }
            else {
                return false;
            }
        },
        toUTCDate: function (date) {
            if (date) {
                let month = date.getMonth() + 1;
                if (month < 10) {
                    month = "0" + month;
                }
                let day = date.getDate();
                if (day < 10) {
                    day = "0" + day;
                }
                return date.getFullYear() + "-" + month + "-" + day + "T00:00:00Z";
            }
            return date;
            //    let utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
            //    return new Date(utc);
        },
        setEntityAttribute: function (entity, attribute, value) {
            if (attribute) {
                const traversed = traverseEntity(entity, attribute);
                traversed[getLeafAttribute(attribute)] = value;
            }
        },
        initDefaultAttributeValues: function (instance, fields) {
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                if (typeof instance.attributes[field.id] !== "undefined") {
                    if (instance.attributes[field.id] === null) {
                        instance.attributes[field.id] = FormUtil.getDefaultFieldValue(field);
                    }
                }
                else {
                    instance.attributes[field.id] = FormUtil.getDefaultFieldValue(field);
                }
            }
        },
        getFieldDataType: function (field) {
            switch (field.fieldType) {
                case Constants.FieldType.CheckboxList:
                case Constants.FieldType.Dropdown:
                case Constants.FieldType.RadioButtonList:
                case Constants.FieldType.SwitchList:
                case Constants.FieldType.ToggleButton:
                    if (field.multiple) {
                        return Constants.FieldDataType.ListItemIdList;
                    }
                    else {
                        return Constants.FieldDataType.ListItemId;
                    }
                case Constants.FieldType.Country:
                    if (field.multiple) {
                        return Constants.FieldDataType.CountryList;
                    }
                    else {
                        return Constants.FieldDataType.Country;
                    }
                case Constants.FieldType.EntityList:
                    if (field.multiple) {
                        return Constants.FieldDataType.EntityIdList;
                    }
                    else {
                        return Constants.FieldDataType.EntityId;
                    }
                case Constants.FieldType.UserList:
                    if (field.multiple) {
                        return Constants.FieldDataType.UserIdList;
                    }
                    else {
                        return Constants.FieldDataType.UserId;
                    }
                case Constants.FieldType.ContactList:
                    if (field.multiple) {
                        return Constants.FieldDataType.ContactIdList;
                    }
                    else {
                        return Constants.FieldDataType.ContactId;
                    }
                case Constants.FieldType.Switch:
                case Constants.FieldType.Checkbox:
                    return Constants.FieldDataType.Boolean;
                case Constants.FieldType.Decimal:
                case Constants.FieldType.SliderDecimal:
                    return Constants.FieldDataType.Decimal;
                case Constants.FieldType.Integer:
                case Constants.FieldType.Rating:
                case Constants.FieldType.SliderInteger:
                    return Constants.FieldDataType.Integer;
                case Constants.FieldType.Date:
                    return Constants.FieldDataType.Date;
                case Constants.FieldType.Text:
                    return Constants.FieldDataType.String;
                case Constants.FieldType.OrgNumber:
                    return Constants.FieldDataType.OrgNumber;
                case Constants.FieldType.Email:
                    return Constants.FieldDataType.Email;
                case Constants.FieldType.TimePeriod:
                    return Constants.FieldDataType.TimePeriod;
                default:
                    return Constants.FieldDataType.None;
            }
        },
        getDefaultFieldValue: function (field) {
            switch (field.fieldType) {
                case Constants.FieldType.CheckboxList:
                case Constants.FieldType.Dropdown:
                case Constants.FieldType.RadioButtonList:
                case Constants.FieldType.SwitchList:
                case Constants.FieldType.ToggleButton:
                case Constants.FieldType.EntityList:
                case Constants.FieldType.UserList:
                case Constants.FieldType.ContactList:
                case Constants.FieldType.Country:
                    if (field.multiple) {
                        let defaultListValues = [];
                        if (field.fieldType !== Constants.FieldType.UserList && field.fieldType !== Constants.FieldType.ContactList && field.fieldType !== Constants.FieldType.EntityList) {
                            // Check if there are default items
                            for (let i = 0; i < field.listItems.length; i++) {
                                const listItem = field.listItems[i];
                                if (listItem.isDefault) {
                                    defaultListValues.push(listItem.id);
                                }
                            }
                        }
                        return defaultListValues;
                    }
                    else {
                        let defaultListValue = "";
                        if (field.fieldType !== Constants.FieldType.UserList && field.fieldType !== Constants.FieldType.ContactList && field.fieldType !== Constants.FieldType.EntityList) {
                            // Check if there are default items
                            for (let i = 0; i < field.listItems.length; i++) {
                                const listItem = field.listItems[i];
                                if (listItem.isDefault) {
                                    defaultListValue = listItem.id;
                                    break;
                                }
                            }
                        }
                        return defaultListValue;
                    }
                case Constants.FieldType.Switch:
                case Constants.FieldType.Checkbox:
                    return false;
                case Constants.FieldType.Decimal:
                case Constants.FieldType.SliderDecimal:
                case Constants.FieldType.Integer:
                case Constants.FieldType.Rating:
                case Constants.FieldType.SliderInteger:
                case Constants.FieldType.Date:
                    return null;
                case Constants.FieldType.Text:
                case Constants.FieldType.OrgNumber:
                case Constants.FieldType.Email:
                    return "";
                case Constants.FieldType.TimePeriod:
                    return "";
                default:
                    return null;
            }
        },
        getEntityAttribute: function (entity, attribute, defaultValue) {
            if (attribute) {
                const traversed = traverseEntity(entity, attribute);
                if (typeof traversed === "undefined") {
                    console.log("Undefined attribute: " + attribute);
                }
                const attributeValue = traversed[getLeafAttribute(attribute)];
                return typeof attributeValue !== "undefined" && attributeValue !== null ? attributeValue : defaultValue;
            }
            return null;
        },
        getFieldScore: function (field, instance) {
            switch (field.fieldType) {
                // Lists
                case Constants.FieldType.CheckboxList:
                case Constants.FieldType.Dropdown:
                case Constants.FieldType.RadioButtonList:
                case Constants.FieldType.SwitchList:
                case Constants.FieldType.ToggleButton:
                    let multiple = field.multiple || field.fieldType === Constants.FieldType.CheckboxList || field.fieldType === Constants.FieldType.SwitchList;
                    let value = FormUtil.getFieldValue(field, instance);
                    let totalScore = null;
                    // Find selected item
                    if (value) {
                        if (multiple) {
                            for (var i = 0; i < value.length; i++) {
                                let listItem = field.listItems.find(o => o.id === value[i]);
                                if (listItem) {
                                    totalScore = totalScore === null ? listItem.score : totalScore + listItem.score;
                                }
                            }
                        }
                        else {
                            let listItem = field.listItems.find(o => o.id === value);
                            if (listItem) {
                                totalScore += listItem.score;
                            }
                        }
                    }
                    return totalScore;
                default:
                    break;
            }
            return null;
        },
        getFieldValue: function (field, instance, defaultNumericValue) {
            if (typeof defaultNumericValue === "undefined") {
                defaultNumericValue = null;
            }
            if (field.fieldType === Constants.FieldType.Name) {
                return instance.name;
            }
            else {
                const attribute = "attributes." + field.id;
                const traversed = traverseEntity(instance, attribute);
                const attributeValue = traversed[getLeafAttribute(attribute)];
                switch (field.fieldType) {
                    // Lists
                    case Constants.FieldType.CheckboxList:
                    case Constants.FieldType.Dropdown:
                    case Constants.FieldType.RadioButtonList:
                    case Constants.FieldType.SwitchList:
                    case Constants.FieldType.ToggleButton:
                    case Constants.FieldType.EntityList:
                    case Constants.FieldType.UserList:
                    case Constants.FieldType.ContactList:
                    case Constants.FieldType.Country:
                        let multiple = field.multiple || field.fieldType === Constants.FieldType.CheckboxList || field.fieldType === Constants.FieldType.SwitchList;
                        return typeof attributeValue !== "undefined" && attributeValue !== null ? attributeValue : multiple ? [] : "";
                    case Constants.FieldType.Switch:
                    case Constants.FieldType.Checkbox:
                        return typeof attributeValue !== "undefined" && attributeValue !== null ? attributeValue : false;
                    case Constants.FieldType.Decimal:
                    case Constants.FieldType.Integer:
                    case Constants.FieldType.Rating:
                    case Constants.FieldType.SliderDecimal:
                    case Constants.FieldType.SliderInteger:
                        return typeof attributeValue !== "undefined" && attributeValue !== null ? attributeValue : defaultNumericValue;
                    case Constants.FieldType.Text:
                    case Constants.FieldType.OrgNumber:
                    case Constants.FieldType.Email:
                        return typeof attributeValue !== "undefined" && attributeValue !== null ? attributeValue : "";
                    case Constants.FieldType.Date:
                        const dateString = typeof attributeValue !== "undefined" && attributeValue !== null ? attributeValue : "";
                        if (dateString !== "") {
                            const date = new Date(dateString);
                            if (isNaN(date)) {
                                return null;
                            }
                            return date;
                        }
                        return null;
                    case Constants.FieldType.TimePeriod:
                        const periodValue = typeof attributeValue !== "undefined" && attributeValue !== null ? attributeValue : "";
                        if (periodValue !== "") {
                            return new TimePeriod(periodValue);
                        }
                        else {
                            return null;
                        }
                    default:
                        break;
                }
            }
            return null;
        },
        setFieldValue: function (field, entity, val) {
            const attribute = "attributes." + field.id;
            const traversed = traverseEntity(entity, attribute);
            traversed[getLeafAttribute(attribute)] = val;
        }
    }
}();
