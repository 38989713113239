import React, { useEffect, useRef, useContext } from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormUtil } from './util/FormUtil'
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import useTheme from '@mui/material/styles/useTheme';
import ListSubheader from '@mui/material/ListSubheader';
import InputAdornment from '@mui/material/InputAdornment';
import { Constants } from './AppConstants'
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ErrorContext } from './ErrorContextProvider';

export default function FormSelect({ entity, setEntity, label, attribute, items, id, multiple, placeholder, defaultValue, valueAttribute, textAttribute,
    tooltip, tooltipPlacement, required, errors, setErrors, errorIdentifier, disableErrors, onChange, placeholderAsItem, variant, sx, readOnly, hasHeaders, disabled, noBorder, icon }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMounted = useRef(false);

    if (placeholder === "" || typeof placeholder === "undefined") {
        placeholder = t("FormFieldPlaceholder").format(label !== "" && typeof label !== "undefined" ? " " + label.toLowerCase() : "");
    }

    // Default error identifier to attribute
    if (typeof errorIdentifier === "undefined") {
        errorIdentifier = attribute;
    }
    // Try getting an error context
    var errorContext = useContext(ErrorContext);
    let ctxErrors, setCtxErrors, ctxShowErrors, setCtxShowErrors, ctxScope;
    if (errorContext) {
        [ctxErrors, setCtxErrors, ctxShowErrors, setCtxShowErrors, ctxScope] = errorContext;
        // If present we replace the errors etc with the variables from the context
        errors = ctxErrors;
        setErrors = setCtxErrors;
        disableErrors = !ctxShowErrors;
        errorIdentifier = ctxScope + errorIdentifier;
    }

    let entityValue = FormUtil.getEntityAttribute(entity, attribute, defaultValue);
    let hasValue = valueExists(entityValue);
    useEffect(() => {
        isMounted.current = true;
        // Assert the entity gets the default value if no value is set
        if (typeof defaultValue !== "undefined") {
            let value = FormUtil.getEntityAttribute(entity, attribute);
            if (typeof value === "undefined") {
                //FormUtil.setEntityAttribute(entity, attribute, defaultValue);
                setEntity((prev) => {
                    let newEntity = { ...prev };
                    FormUtil.setEntityAttribute(newEntity, attribute, defaultValue);
                    return newEntity;
                });
                return;
            }
        }
        //checkRequired(entity);
        return () => {
            isMounted.current = false;
            // Also clear errors before unmount
            if (setErrors) {
                setErrors((prev) => {
                    let newErrors = { ...prev };
                    newErrors[errorIdentifier] = false;
                    return newErrors;
                });
            }
        }
    }, []);
    useEffect(() => {
        checkRequired();
    }, [entityValue])

    function checkRequired(newEntity) {
        if (required && errors) {
            setErrors((prev) => {
                let entityValue = FormUtil.getEntityAttribute(entity, attribute, defaultValue);
                let missing = !valueExists(entityValue);
                if (prev[attribute] === missing) {
                    return prev; // Skip the update
                }
                else {
                    let newErrors = { ...prev };
                    newErrors[errorIdentifier] = missing;
                    return newErrors;
                }
            });
        }
    }

    if (typeof valueAttribute === "undefined") {
        valueAttribute = "value";
    }
    if (typeof textAttribute === "undefined") {
        textAttribute = "text";
    }

    function handleChange(e) {
        const { name, value } = e.target;
        if (onChange) {
            onChange(e);
        }
        setEntity((prev) => {
            let newEntity = { ...prev };
            FormUtil.setEntityAttribute(newEntity, name, value);
            //checkRequired(newEntity);
            return newEntity;
        });
    }

    let additionalInputProps = {};

    if (readOnly) {
        additionalInputProps.readOnly = true;
    }

    let additionalProps = {};
    if (icon) {
        additionalProps.startAdornment = <InputAdornment position="start">
            {icon}
        </InputAdornment>;
    }
    else {
        // Check if selected item has icon, then we can use that as a start adornment
        if (items.findIndex(o => Boolean(o.icon)) !== -1) {
            // Then get the icon of the selected item
            const selectedItem = items.find(o => o[valueAttribute] === entityValue);
            if (selectedItem && selectedItem.icon) {
                additionalProps.startAdornment = <InputAdornment position="start">
                    {selectedItem.icon}
                </InputAdornment>;
            }
        }
    }

    // Create an id for the input label if not specified
    let dateStamp = new Date().valueOf();
    let labelId = id !== null ? id : attribute + "_" + dateStamp + "_inputlabel";

    let list = items !== null ? [...items] : [];
    if (placeholder && placeholderAsItem) {
        // Then add an additional item first
        let item = {};
        item[valueAttribute] = "";
        item[textAttribute] = placeholder + "";
        item.isPlaceholder = true;
        if (list.length > 0) {
            if (!list[0].isPlaceholder) { // If not already added
                list.splice(0, 0, item);
            }
        }
        else {
            list.push(item);
        }
    }

    let selectedValues = FormUtil.getEntityAttribute(entity, attribute, defaultValue);
    // Assert that the selectedValue is among the options
    if (list) {
        if (Array.isArray(selectedValues)) {
            let filteredValues = [];
            for (let i = 0; i < selectedValues.length; i++) {
                let selectedValue = selectedValues[i];
                let valueExists = false;
                for (let j = 0; j < list.length; j++) {
                    if (list[j][valueAttribute] === selectedValue) {
                        valueExists = true;
                        break;
                    }
                }
                if (valueExists) {
                    filteredValues.push(selectedValue);
                }
            }
            if (filteredValues.length === 0 && typeof defaultValue !== "undefined") {
                filteredValues = defaultValue;
            }
            selectedValues = filteredValues;
        }
        else {
            let valueExists = false;
            for (let i = 0; i < list.length; i++) {
                if (list[i][valueAttribute] === selectedValues) {
                    valueExists = true;
                    break;
                }
            }
            if (!valueExists) {
                selectedValues = defaultValue;
            }
        }
    }

    function valueExists(val) {
        if (multiple) {
            if (val.length > 0) {
                return true;
            }
        }
        else {
            if (val !== null && val !== "" && typeof val !== "undefined") {
                return true;
            }
        }
        return false;
    }


    function renderMenuItem(item, index) {
        let itemStyle = null;
        if (hasHeaders) {
            itemStyle = { paddingLeft: "35px" };
        }

        if (item.isDivider) {
            return <Divider key={index} />;
        }
        else if (item.isHeader) {
            return <ListSubheader key={index}>{item.header}</ListSubheader>
        }
        else if (item.isPlaceholder) {
            return <MenuItem key={index} value={""} ><span style={{ opacity: 0.5 }}>{placeholder}</span></MenuItem>
        }
        else {
            return <MenuItem key={index} disabled={item.disabled} value={item[valueAttribute]} sx={{ ...itemStyle, fontWeight: item.bold ? "bold" : "" }}>
                {item.icon && typeof item.icon === "object" && <ListItemIcon>{item.icon}</ListItemIcon>}
                {item.description ?
                    <>
                        <ListItemText>{item[textAttribute]}</ListItemText>
                        <Typography variant="body2">
                            {item.description}
                        </Typography>
                    </>
                    :
                    item[textAttribute]}
            </MenuItem>
        }
    }

    if (typeof variant === "undefined") {
        variant = theme.inputs.variant;
    }

    function renderValue(value) {
        if (value !== null) {
            for (let i = 0; i < items.length; i++) {
                if (items[i][valueAttribute] === value) {
                    return items[i][textAttribute];
                }
            }
        }
        return placeholder ? placeholder : "";
    }

    if (noBorder) {
        if (typeof sx === "undefined") {
            sx = {};
        }
        sx = {
            ...sx, '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
            }
        };
    }

    let select = (
        <FormControl
            required={required}
            error={errors ? errors[errorIdentifier] && !disableErrors : false}
            fullWidth
            variant={variant}>
            <InputLabel id={labelId} shrink={placeholder && placeholderAsItem && !hasValue ? true : Boolean(hasValue || placeholder)}>{label}</InputLabel>
            <Select
                sx={sx}
                labelId={labelId}
                name={attribute}
                size="small"
                renderValue={renderValue}
                inputProps={additionalInputProps}
                multiple={multiple}
                displayEmpty={placeholder ? true : false}
                value={selectedValues}
                onChange={handleChange}
                label={label}
                disabled={disabled}
                {...additionalProps}
            >
                {list.map((item, index) => (
                    renderMenuItem(item, index)
                ))}
            </Select>
        </FormControl>
    );
    if (tooltip) {
        return (
            <Tooltip title={tooltip} placement={tooltipPlacement ? tooltipPlacement : Constants.TooltipPlacement} >
                {select}
            </Tooltip>
        );
    }
    else {
        return select;
    }
}
