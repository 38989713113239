import { throwError } from '../library/Error';

export class TimePeriod {
    count = null;
    unit = "m";

    constructor(stringValue) {
        if (stringValue !== null && typeof stringValue !== "undefined" && stringValue !== "") {
            stringValue = stringValue.replace(/\s/g, "");
            if (stringValue.endsWith("d")) {
                this.unit = "d";
            }
            else if (stringValue.endsWith("m")) {
                this.unit = "m";
            }
            else if (stringValue.endsWith("y")) {
                this.unit = "y";
            }
            // Remove the unit
            if (stringValue) {
                stringValue = stringValue.replace(this.unit, "");
            }

            if (stringValue !== "") {
                const count = parseInt(stringValue);
                if (!isNaN(count)) {
                    this.count = count;
                }
                else {
                    this.count = null;
                }
            }
            else {
                this.count = null;
            }

            this.count = stringValue;
            if (stringValue) {
            }
        }
        else {
            this.count = null;
        }
    }

    static addMonths(date, months) {
        //return date.AddDays(1).AddMonths(Count).AddDays(-1);
        date.setDate(date.getDate() + 1);
        date.setMonth(date.getMonth() + months);
        date.setDate(date.getDate() - 1);
        return date;
    }
    static addYears(date, years) {
        //return date.AddDays(1).AddMonths(Count).AddDays(-1);
        date.setDate(date.getDate() + 1);
        date.setYear(date.getYear() + years);
        date.setDate(date.getDate() - 1);
        return date;
    }

    static getToday() {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    }

    static addDays(date, days) {
        date.setDate(date.getDate() + days);
        return date;
    }

    static toMonths(timeSpan) {
        if (timeSpan.unit === "d") {
            return timeSpan.getIntCount() / 30;
        }
        else if (timeSpan.unit === "m") {
            return timeSpan.getIntCount();
        }
        else if (timeSpan.unit === "y") {
            return timeSpan.getIntCount()*12;
        }
    }
    toString() {
        if (this.count === null) {
            return "";
        }
        return this.count + this.unit;
    }
    equalTo(timeSpan) {
        return TimePeriod.toMonths(this) === TimePeriod.toMonths(timeSpan);
    }
    greaterThan(timeSpan) {
        return TimePeriod.toMonths(this) > TimePeriod.toMonths(timeSpan);
    }
    greaterThanOrEqual(timeSpan) {
        return TimePeriod.toMonths(this) >= TimePeriod.toMonths(timeSpan);
    }
    lessThan(timeSpan) {
        return TimePeriod.toMonths(this) < TimePeriod.toMonths(timeSpan);
    }
    lessThanOrEqual(timeSpan) {
        return TimePeriod.toMonths(this) <= TimePeriod.toMonths(timeSpan);
    }
    getIntCount() {
        let intCount = 0;
        if (this.count) {
            intCount = parseInt(this.count);
            if (isNaN(intCount)) {
                intCount = 0;
            }
        }
        return intCount;
    }
    addTo(date) {
        if (this.unit === "d") {
            return TimePeriod.addDays(date, this.getIntCount());
        }
        else if (this.unit === "m") {
            return TimePeriod.addMonths(date, this.getIntCount());
        }
        else if (this.unit === "y") {
            return TimePeriod.addYears(date, this.getIntCount());
        }
        else {
            throwError("Unsupported time unit");
        }
    }
    subtractFrom(date) {
        if (this.unit === "d") {
            return TimePeriod.addDays(date, -this.getIntCount());
        }
        else if (this.unit === "m") {
            return TimePeriod.addMonths(date, -this.getIntCount());
        }
        else if (this.unit === "y") {
            return TimePeriod.addMonths(date, -12 * this.getIntCount());
        }
        else {
            throwError("Unsupported time unit");
        }
    }

}
